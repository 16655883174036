<template>
    <div class="page">
        <Part1 />
        <div class="mains">
            <div class="c-left">
                <div class="border"></div>
                <p class="fund">资产配置</p>
                <p class="txt">
                    随着越来越多的共同因子在不同的资产与市场中被发掘出来，基于风险因子的投资管理和风险管理成为新的潮流。在传统的基于资产和产品的分析之上，FoF／MoM的管理者能充分理解不同市场的收益、风险与相互作用，并将投资观点应用在基金与基金经理的配置决策上。
                </p>
                <p class="txt">
                    智君科技的资产配置管理系统融合了资产分类和风险因子，不仅仅能为您提供公募基金、私募基金、基金经理的评级评分、风险预测和绩效归因，还能帮您在母基金层面挖掘投资机会，控制风险暴露，优化组合权重，并任意选择在资产类别、因子、基金或基金经理层级归因组合整体的绩效与风险。
                </p>
                <p class="solve">
                    <span>解决方案：</span>
                    <a><router-link to="/attributionperform">绩效归因<svg-icon icon-class="圆箭头" style="padding:0 3px"></svg-icon></router-link></a>
                    <a style="margin:0 18px"><router-link to="/product">公募基金投资<svg-icon icon-class="圆箭头" style="padding:0 3px"></svg-icon></router-link></a>
                </p>
            </div>
            <div class="c-right">
                <img src="../../assets/p1.png" alt="" style="width:474px;height:317px">
            </div>
        </div>
        <ApplyForm />
    </div>
    
</template>

<script>
import Part1 from './part1'
import ApplyForm from '../../components/ApplyForm'
export default {
    name:'AssetAllocation',
    components: { Part1,ApplyForm },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.mains{
    width:1340px;
    height: 317px;
    margin:0 auto;
    // background:yellow;
    padding:79px 0 90px 0;
    .c-left{
        height:317px;
        // background:red;
        // width:15%;
        float:left;
        margin-left:5.4%;
        .border{
            width:130px;
            height:8px;
            background:#2E5082;
        }
        .fund{
            // width:308px;
            height:40px;
            font-size:32px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(51,51,51,1);
            line-height:32px;
            margin:15px 0;
            text-align:left;
        }
        .txt{
            width:658px;
            // height:80px;
            // float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            text-indent: 28px;
            text-align:left;
            // margin-bottom:74px;
            
        }
        .mark{
                color:#2E5082;
                font-weight:bold;
            }
        .solve{
            // width:180px;
            height:20px;
            float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            // font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            margin-top:19px;
            padding:0;
            a{
                text-decoration: none;
                color:#476CCB;
            }
        }

    }
    .c-right{
        width:474px;
        height:317px;
        float:right;
        margin-right:70px;
    }
    
}
</style>